.timeline_tl {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
}

.left_tl {
    left: 0;
}
.container_tl {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}
.right_tl {
    left: 50%;
}
.timeline_tl::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #eee;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}
.left_tl::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #fcf6f1;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #fcf6f1;
}
.content_tl {
    padding: 20px 30px;
    background-color: #fcf6f1;
    position: relative;
    border-radius: 6px;
}
.container_tl::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -11px;
    background-color: white;
    border: 4px solid #FF9F55;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.right_tl::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #fcf6f1;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fcf6f1 transparent transparent;
}
.right_tl::after {
    left: -16px;
}

.container_tl::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -11px;
    background-color: white;
    border: 4px solid #FF9F55;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .container_tl::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }
    .timeline_tl::after {
        left: 31px;
    }
    .left_tl::after, .right_tl::after {
        left: 15px;
    }
    .container_tl::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }
    .left_tl::after, .right_tl::after {
        left: 15px;
    }
    .container_tl::after {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        right: -11px;
        background-color: white;
        border: 4px solid #FF9F55;
        top: 15px;
        border-radius: 50%;
        z-index: 1;
    }
    .container_tl {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
        left:1px;
    }
}

/* SkeletonLoader.css */
.skeleton-loader {
    padding: 20px;
  }
  .skeleton-title {
    margin: 20px 0;
  }
  .skeleton-content {
    margin-top: 20px;
  }
  