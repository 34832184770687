.carousel-container {
  width: 100%;
  max-width: 1160px;
  margin: auto;
  padding: 40px 20px;
}

.carousel-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 340px;
  height: 200px;
  background-color: rgb(15, 160, 143);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.card-content p {
  color: white;
  font-size: 14px;
  line-height: 10px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .carousel-title {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .carousel-title {
    font-size: 18px;
  }
}


.carousel-container {
  padding: 20px;
}

.carousel-title {
  text-align: center;
  margin-bottom: 20px;
}

.slide {
  padding: 10px;
}

.mantine-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ddd;
  height: 400px; /* Fix the height of the card */
  max-width: 100%; /* Ensure card width is responsive */
}

.mantine-card-section {
  height: 50%; /* Image will take 50% of the card's height */
}

.mantine-card-image {
  width: 100%;
 
  object-fit: cover;
}



.mantine-card-content {
  padding: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 50%; /* Content takes the other 50% */
  justify-content: space-between; /* Ensures proper spacing between title, description, and button */
}

.mantine-card-title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 5px;
  padding-top: 10px;
  
}



.mantine-card-description {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 5px;
}

.mantine-card-button {
  padding: 6px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
 
}

.mantine-card-button:hover {
  background-color: #0056b3;
}



@media (max-width: 768px) {
  .mantine-card {
    height: 450px; /* Adjust height for smaller screens */
  }
  .mantine-card-section {
    height: 50%; /* Image will take 50% of the card's height */
  }
  .mantine-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mantine-card-title {
    padding-top: 0px;
  }
}

@media (max-width: 480px) {
  .mantine-card {
    height: 500px; /* Adjust height further for mobile */
  }
}
