

* { 
    box-sizing: border-box;
} 

* { 
    outline: none; 
    padding: 0;
} 

.d-flex { 
    display: flex ;
} 

.justify-content-center { 
    justify-content: center ;
} 



@media (prefers-reduced-motion: no-preference){ 
  :root { 
    scroll-behavior: smooth;
  } 
}     

.flex-row { 
    flex-direction: row ;
} 

.flex-wrap { 
    flex-wrap: wrap ;
} 

*,:before,:after { 
    box-sizing: border-box;
} 

:before { 
    margin: 0; 
    padding: 0;
} 

:after { 
    margin: 0; 
    padding: 0;
} 

:selection { 
    color: #fff; 
    background: #11bca3;
} 

:selection { 
    background: #36f7da; 
    color: #fff;
} 


@media screen and (max-width:768px) {
    .work-bx { 
    
        margin: 15px;
        min-width: 40%;
       
        
       } 
       
}

@media screen and (min-width:768px) {
    .work-bx { 
    
        margin: 15px;
        width: 20%;
       position: relative;
       padding: 20px;
        
       } 
       
}


.work-bx  { 
    margin-top: 30px;
} 

@media screen and (min-width:700px){
    .work-bx:after { 
    content: ""; 
    height: 0; 
    width: 100%; 
    background-color: #648dbd; 
    position: absolute; 
    top: 0; 
    right: 0; 
    z-index: -10; 
    -webkit-transition: all 0.5s; 
    -ms-transition: all 0.5s; 
    transition: all 0.5s; 
    transform-origin: top; 
    -moz-transform-origin: top; 
    -ms-transform-origin: top; 
    -webkit-transform-origin: top; 
    -o-transform-origin: top;
    border-radius: 15px;
} 
}

.work-bx.active:after,.work-bx:hover:after { 
    height: 100%; 
    top: auto; 
    bottom: 0; 
    transform-origin: bottom; 
    -moz-transform-origin: bottom; 
    -ms-transform-origin: bottom; 
    -webkit-transform-origin: bottom; 
    -o-transform-origin: bottom; 
    animation-duration: 0.5s;
} 

.work-bx .work-num-bx  { 
    -webkit-transition: all 0.5s; 
    -ms-transition: all 0.5s; 
    transition: all 0.5s; 
    z-index: 9; 
    font-size: 80px; 
    font-weight: 900; 
    margin-bottom: 30px; 
    line-height: 80px; 
   
    text-shadow: 0 10px 0 #c0d8c8; 
    color: transparent;
} 

.work-bx .work-num-bx  { 
    
    text-shadow: 0 10px 0 #11bca3;
} 

 .work-bx:hover .work-num-bx { 
    text-shadow: 0 10px 0 #ffbebc; 
    
} 
@media screen and (min-width:700px){
.work-bx.active .work-num-bx, .work-bx:hover .work-num-bx { 
    text-shadow: 0 10px 0 #ffbebc; 
    
} 
}

.work-bx .work-content  { 
    transition: all 0.5s; 
    -webkit-transition: all 0.5s; 
    -ms-transition: all 0.5s;
} 



.btn { 
    display: inline-block; 
    font-weight: 400; 
    line-height: 1.5; 
    color: #444; 
    text-align: center; 
    text-decoration: none; 
    vertical-align: middle; 
    cursor: pointer; 
    user-select: none; 
    background-color: transparent; 
    border: 1px solid transparent; 
    padding: 0.375rem 0.75rem; 
    font-size: 1rem; 
    border-radius: 0.25rem; 
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} 

.btn-success { 
    color: #000; 
    background-color: #99c2a5; 
    border-color: #99c2a5;
} 

.btn { 
    padding: 15px 30px; 
    border-radius: 8px; 
    text-decoration: none; 
    font-weight: 500; 
    font-size: 14px; 
    -webkit-transition: all 0.5s; 
    -ms-transition: all 0.5s; 
    transition: all 0.5s; 
    border-width: 2px; 
    line-height: 1.2;
} 

.btn-success { 
    background-color: #99c2a5;
} 

.btn-success { 
    color: white;
} 

.work-bx .btn  { 
    font-weight: 600;
} 

.work-bx .btn-success  { 
    background-color: #11bca3; 
    border-color: #11bca3;
} 

a:hover { 
    color: #087678;
} 

a:hover { 
    text-decoration: none; 
    outline: none; 
    color: #11bca3;
} 

.btn:hover { 
    color: #444;
} 

.btn-success:hover { 
    color: #000; 
    background-color: #a8cbb3; 
    border-color: #a3c8ae;
} 

 .work-bx:hover .btn { 
    background-color: #fff; 
    border-color: #fff; 
    color: #648dbd;
} 

.work-bx.active .btn, .work-bx:hover .btn { 
    background-color: #fff; 
    border-color: #fff; 
    color: #648dbd;
} 

h5 { 
    margin-top: 0; 
    margin-bottom: 0.5rem; 
    font-weight: 500; 
    line-height: 1.2; 
    color: #14181d;
} 

h5 { 
    font-size: 1.25rem;
} 

h5 { 
    font-size: 20px; 
    font-weight: 700;
} 

/* Hover effect: Change text color to white */
@media screen and (min-width:700px){
.work-bx:hover .work-content h5,
.work-bx:hover .work-content p {
    color: white;
    transition: color 0.3s ease-in-out;
}
}

h5 { 
    font-family: "Montserrat", sans-serif; 
    color: #002c84; 
    line-height: 1.3;
} 

.text-dark { 
    --bs-text-opacity: 1; 
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) ;
} 

.mb-10 { 
    margin-bottom: 10px;
} 

p { 
    margin-top: 0; 
    margin-bottom: 1rem;
} 

.work-bx .work-content p  { 
    font-size: 14px; 
    line-height: 24px; 
    font-weight: 500; 
    margin-bottom: 30px;
} 

.work-bx .work-content p  { 
    min-height: 10px;
} 

.fas { 
    -moz-osx-font-smoothing: grayscale; 
    -webkit-font-smoothing: antialiased; 
    display: inline-block; 
    font-style: normal; 
    font-variant: normal; 
    text-rendering: auto; 
    line-height: 1;
} 

.fas { 
    font-family: "Font Awesome 5 Free";
} 

.fas { 
    font-weight: 900;
} 

.btn-icon-bx { 
    width: 40px; 
    height: 40px; 
    background: #fff; 
    border-radius: inherit; 
    line-height: 40px; 
    display: inline-block; 
    margin: -12px -27px -12px 15px; 
    color: #000;
} 

.btn-success .btn-icon-bx  { 
    color: #99c2a5;
} 

.fa-chevron-right:before { 
    content: "\f054";
} 

.service-title {
    padding-top: 50px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
